@import '@amzn/awsui-design-tokens/polaris.scss';

.lab-card {
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3),
        1px 1px 1px 0 rgba(0, 28, 36, 0.15),
        -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
    border-top: 1px solid #eaeded;
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 2rem;
    word-wrap: break-word;

    p {
        font-size: 1.4rem;
        padding: 1.5rem 2rem;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0.5rem;
        }
    }

    h3 {
        font-size: 1.8rem;
        line-height: 2rem;
        padding: 0.5rem 0;
        display: inline-block;
        margin: 0.5rem 1rem 1rem 0;
    }

    .lab-card__header {
        padding: 1.5rem 2rem 0;
    }

    .lab-card__duration {
        text-transform: uppercase;
        transform: translateY(-0.1rem);
        color: $color-text-body-secondary;
        display: inline-block;

        .lab-card__duration-icon {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}

.lab-card__actions {
    position: relative;
    padding: 2rem;
    text-align: right;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 1.5rem;
        right: 1.5rem;
        height: 1px;
        background-color: $color-text-input-placeholder;
    }
}
