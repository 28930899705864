@mixin container {
    margin: auto;
    width: 90%;
    max-width: 1100px;
}

.class-meta-container {
    @include container();
    margin-top: -5rem;
    position: relative;
    z-index: 2;
}

.class-lab-list-container {
    @include container();
    margin-top: 2rem;

    & > [data-testid='section-wrapper'] {
        margin-top: 32px;
    }
}
